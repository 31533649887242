<template>
    <v-dialog v-model="dialog" max-width="900" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>AMB Autorizados a retirar</h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
                    <v-col cols="12" sm="12" md="12" class="pt-3">
                        <v-form @submit.prevent="buscar">
                            <v-row :no-gutters="$vuetify.breakpoint.xs">
                                <v-col cols="12" sm="3" md="3" class="py-1">
                                    Empresa
                                    <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id"
                                        hide-details outlined dense clearable :items="familias"
                                        @change="setEmpresas"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" class="py-1">
                                    Marca
                                    <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id"
                                        hide-details outlined dense clearable :items="empresasFiltradas.filter(emp => emp.familia === data.familia)
                                            " @change="setSucursal"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" class="py-1">
                                    Sucursal
                                    <v-autocomplete v-model="data.sucursal" item-text="provincia" item-value="id"
                                        hide-details outlined dense clearable :items="sucursalesFiltradas.filter(
                                            suc => suc.familia_id === data.familia
                                        )
                                            "></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="5" md="5" class="py-1">
                                    Nombre
                                    <v-text-field v-model.trim="data.nombre" hide-details outlined dense
                                        clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" md="12" class="pt-0 d-flex justify-end" style="">
                                    <BtnConfirmar @action="nuevo()" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="py-3">
                        <v-data-table class="cebra elevation-0 mt-2" :items-per-page="5" item-key="id" sort-by="id" :headers="headers"
                            :items="autorizados" :loading="load" sort-desc dense :search="search">
                            <template v-for="header in headers.filter(header =>
                                header.hasOwnProperty('formatter')
                            )" v-slot:[`item.${header.value}`]="{ value }">
                                {{ header.formatter(value) }}
                            </template>
                            <template v-slot:top>
                                <v-row class="d-flex justify-start pa-2" no-gutters>
                                    <v-col cols="6" sm="3" xl="3">
                                        <SearchDataTable v-model="search" />
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:[`item.acciones`]="{ item }">
                                <BtnConfirmar :icono="!item.Habilitado ? 'fas fa-check' : 'fas fa-ban'"
                                    :color="!item.Habilitado ? 'success' : 'error'"
                                    :title="!item.Habilitado ? 'Habilitar' : 'Inhabilitar'" :texto="!item.Habilitado
                                        ? `¿Desea habilitar</strong>?`
                                        : `¿Desea inhabilitar</strong>?`
                                        " :icon_button="true" :small="true" @action="
                                            !item.Habilitado ? habilitar(item) : inhabilitar(item)
                                            " />

                                <!-- <BtnConfirmar :icon_button="true" :small="true" icono="fas fa-trash"
                                    @action="eliminar(item)" color="error" title="Eliminar"
                                    texto="¿Desea eliminar este registro?" /> -->
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../util/BtnFiltro.vue'
import NuevoVoucher from './NuevoVoucher.vue'
import moment from 'moment'
import EditarVoucher from './EditarVoucher.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import SearchDataTable from '../../util/SearchDataTable.vue'

export default {
    data() {
        return {
            load: false,
            modalNuvoVoucher: false,
            modalEditar: false,

            sucursalesFiltradas: [],
            empresasFiltradas: [],

            vouchers: [],
            voucherSeleccionado: {},

            search: '',

            data: {
                nombre: '',
                familia: null,
                empresa: null,
                sucursal: null
            },

            headers: [
                { text: 'Nombre', value: 'Nombre' },
                { text: 'Empresa', value: 'nombre_familia' },
                { text: 'Sucursal', value: 'provincia_sucursal' },

                {
                    text: 'Fecha creación',
                    value: 'Fecha_crea',
                    formatter: this.formatDate
                },
                {
                    text: 'Usuario log',
                    value: 'usuario'
                },
                { text: 'Acciones', value: 'acciones', sortable: false }
            ],

            autorizados: []
        }
    },

    methods: {
        async buscar() {
            await this.$store
                .dispatch('vouchersCombustible/getVouchers', {
                    familia: this.filtro.familia,
                    empresa: this.filtro.empresa,
                    sucursal: this.filtro.sucursal
                })
                .then(res => {
                    if (res.exito) {
                        this.vouchers = res.data
                    } else {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                        })
                    }
                })
        },

        setEmpresas() {
            const empresa = this.empresas.filter(
                emp => emp.familia === this.filtro.familia
            )
            empresa.length === 1 ? (this.filtro.empresa = empresa[0].id) : null
        },

        setSucursal() {
            const sucursal = this.sucursales.filter(
                suc => suc.familia_id === this.filtro.familia
            )
            sucursal.length === 1 ? (this.filtro.sucursal = sucursal[0].id) : null
        },

        abrirModalNuevoVoucher() {
            // ANTES DE ABRIR ESTE MODAL NECESITO SABER SI NO EXISTE UNO PARA ESA EMPRESA
            this.modalNuvoVoucher = true
        },

        abrirModalEditar(item) {
            this.voucherSeleccionado = item
            this.modalEditar = true
        },

        async handleActualizarBusqueda(event) {
            if (event) {
                await this.buscar()
            }
        },

        formatDate(date, format) {
            if (!date) return ''
            return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
        },

        async nuevo() {

            if (!this.data.familia || !this.data.empresa || !this.data.sucursal || !this.data.nombre) {
                this.$store.dispatch('show_snackbar', {
                    text: 'Todos los campos son obligatorios.',
                    color: 'error'
                })
                return
            }
            this.$store.state.loading = true
            this.$store.dispatch('recibosCombustible/nuevoRetira', {
                ...this.data
            }).then(res => {
                if (res.exito) {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                    })
                    this.autorizados.push(res.data)
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.$store.state.loading = false
        },

        async inhabilitar(item) {
            this.$store.state.loading = true
            this.$store.dispatch('recibosCombustible/inhabilitarRetira', {
                retira_id: item.Retira_id
            }).then(res => {
                if (res.exito) {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                    })

                    this.autorizados.forEach(el => {
                        if (el.Retira_id === item.Retira_id) {
                            el.Habilitado = 0
                        }
                    })
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.$store.state.loading = false
        },

        async habilitar(item) {
            this.$store.state.loading = true
            this.$store.dispatch('recibosCombustible/habilitarRetira', {
                retira_id: item.Retira_id
            }).then(res => {
                if (res.exito) {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                    })
                    this.autorizados.forEach(el => {
                        if (el.Retira_id === item.Retira_id) {
                            el.Habilitado = 1
                        }
                    })
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.$store.state.loading = false
        },

        async eliminar(item) {
            this.$store.state.loading = true
            this.$store.dispatch('recibosCombustible/eliminarRetira', {
                retira_id: item.Retira_id
            }).then(res => {
                if (res.exito) {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                    })
                    this.autorizados = this.autorizados.filter(el => el.Retira_id != item.Retira_id)
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.$store.state.loading = false
        },

        async getAutorizados() {
            this.$store.state.loading = true
            this.$store.dispatch('recibosCombustible/getAutorizadosRetirar').then(res => {
                if (res.exito) {
                    this.autorizados = res.data
                    this.autorizados.forEach(aut => {
                        aut.nombre_familia = this.familias.find(empresa => empresa.id === aut.Familia_id)?.nombre
                        aut.provincia_sucursal = this.sucursales.find(suc => suc.id === aut.Sucursal_id)?.provincia
                    })
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.$store.state.loading = false
        },

        setEmpresas() {
            const empresa = this.empresas.filter(
                emp => emp.familia === this.data.familia
            )
            empresa.length === 1 ? (this.data.empresa = empresa[0].id) : null
        },

        setSucursal() {
            const sucursal = this.sucursales.filter(
                suc => suc.familia_id === this.data.familia
            )
            sucursal.length === 1 ? (this.data.sucursal = sucursal[0].id) : null
        },
    },

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },

        ...mapState(['familias', 'empresas']),
        ...mapState('genericos', ['sucursales'])
    },

    props: {
        value: Boolean
    },

    async created() {
    },

    components: {
        BtnFiltro,
        NuevoVoucher,
        EditarVoucher,
        BtnConfirmar,
        SearchDataTable
    },

    watch: {
        async dialog(val) {
            if (val) {
                this.empresasFiltradas = this.empresas
                this.sucursalesFiltradas = this.sucursales
                await this.getAutorizados()
                this.data.nombre = ''
                this.data.familia = null
                this.data.empresa = null
                this.data.sucursal = null
            }
        }
    }
}
</script>