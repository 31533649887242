<template>
    <v-dialog v-model="dialog" max-width="800" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>Subir archivo</h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="pt-5 align-center">
                    <v-col cols="12" sm="3" md="3" class="py-1">
                        Empresa
                        <v-autocomplete v-model="filtro.empresa" item-text="Nombre" return-object hide-details outlined
                            dense clearable :items="empresas"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="py-1">
                        <FileDropzone :files.sync="archivo" :tipos="tipos_validos" :multiple="false" :max_size="50" />
                    </v-col>

                    <v-col cols="12" class="py-1">
                        <div style="display: flex; justify-content: end">
                            <BtnConfirmar @action="subir" nombre="subir" icono="fas fa-file-upload" />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import FileDropzone from "../util/FileDropzone.vue";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import Papa from 'papaparse'
import moment from "moment";

export default {
    data() {
        return {
            archivo: [],
            cuit_cuil: "",
            tipos_validos: [".csv"],
            filtro: {
                empresa: null,
            },
            tipo_archivo_cargado: '',
            empresas: [],
            datos: [],
            archivoPortalIVA: []
        };
    },
    methods: {
        async subir() {
            if (!(this.filtro.empresa)) {
                this.$store.dispatch("show_snackbar", {
                    text: 'Debes seleccionar una empresa.',
                    color: "warning",
                });
                return
            }
            else {
                this.datos = this.datos.filter(el => el.fecha_emision != null && el.nro_comprobante != null)
                this.$store.state.loading = true
                await this.$store
                    .dispatch("comprobantesAfip/enviarDatos", {
                        data: this.datos,
                        empresa: this.filtro.empresa.Id,
                    }).then(async res => {
                        if (res.exito) {
                            // Procesar el Portal IVA
                            let minFecha = moment(this.datos[0].fecha_emision, "DD/MM/YYYY");
                            let maxFecha = moment(this.datos[0].fecha_emision, "DD/MM/YYYY");
                            this.datos.forEach((factura) => {
                                const fecha = moment(factura.fecha_emision, "DD/MM/YYYY");
                                if (fecha.isBefore(minFecha)) {
                                    minFecha = fecha;
                                }
                                if (fecha.isAfter(maxFecha)) {
                                    maxFecha = fecha;
                                }
                            });

                            minFecha = minFecha.format('DD/MM/YYYY')
                            maxFecha = maxFecha.format('DD/MM/YYYY')
                            await this.$store
                                .dispatch("comprobantesAfip/procesarDatos", {
                                    reprocesar: false,
                                    fecha_desde: minFecha,
                                    fecha_hasta: maxFecha
                                }).then(res => {
                                    if (res.exito) {
                                        this.$swal.fire({
                                            icon: 'success',
                                            title: 'El archivo se subió y procesó con éxito.'
                                        })
                                        this.dialog = false
                                    } else {
                                        this.$store.dispatch("show_snackbar", {
                                            text: res.message,
                                            color: "error",
                                        });
                                    }
                                })
                        } else {
                            if (res.data) {
                                let mensaje = `Fallaron los siguientes números de comprobantes: ${res.data.join(',')}`
                                this.$store.dispatch("show_snackbar", {
                                    text: mensaje,
                                    color: "error",
                                });
                            } else {
                                this.$store.dispatch("show_snackbar", {
                                    text: res.message,
                                    color: "error",
                                });
                            }
                        }
                    })
                this.$store.state.loading = false

            }
        },
        formatDate(dateString) {
            if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
                return dateString; // Retornar tal cual si ya está en el formato esperado
            }
            if (moment(dateString, 'YYYY-MM-DD', true).isValid()) {
                return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY'); // Convertir a 'DD/MM/YYYY'
            }
        },
        validacionItemColumnaString(value) {
            return (value === undefined || (typeof value === 'string' && value.length === 0)) ? null : value;
        },
        validarColumnasPortal(array) {
            const propiedadesConvertirANumber = [
                "credito_fiscal_computable",
                "importe_exento",
                "importe_iva_2_5_porc",
                "importe_iva_5_porc",
                "importe_iva_10_5_porc",
                "importe_iva_21_porc",
                "importe_iva_27_porc",
                "importe_no_gravado",
                "importe_otros_tributos",
                "importe_total",
                "importe_impuestos_internos",
                "importe_impuestos_municipales",
                "importe_percepciones_o_pagos_cta_otros_imp_nac",
                "importe_percepciones_ing_brutos",
                "importe_percepciones_o_pago_cuenta_iva",
                "neto_gravado_iva_0_porc",
                "neto_gravado_iva_2_5_porc",
                "neto_gravado_iva_5_porc",
                "neto_gravado_iva_10_5_porc",
                "neto_gravado_iva_21_porc",
                "neto_gravado_iva_27_porc",
                "nro_comprobante",
                "punto_de_venta",
                "tipo_doc_vendedor",
                "total_iva",
                "total_neto_gravado"
            ];

            array.forEach(dato => {
                Object.keys(dato).forEach(key => {
                    dato[key] = this.validacionItemColumnaString(dato[key]);
                    if (propiedadesConvertirANumber.includes(key)) {
                        const numValue = dato[key] ? parseFloat(dato[key].replace(',', '.')) : null;
                        dato[key] = (isNaN(numValue) || numValue === undefined || !(numValue)) ? null : numValue;
                    }
                });
            });

        },

        parseCSV(file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    this.datos = results.data; this.cuit_cuil = this.datos.length > 0 ? this.datos[0].NroDocEmisor : '';

                    const datosParseados = this.datos.map(comprobante => {

                        let {
                            'Crédito Fiscal Computable': credito_fiscal_computable,
                            'Denominación Vendedor': denominacion_vendedor,
                            'Fecha de Emisión': fecha_emision,
                            'Importe Exento': importe_exento,
                            'Importe IVA 2,5%': importe_iva_2_5_porc,
                            'Importe IVA 5%': importe_iva_5_porc,
                            'Importe IVA 10,5%': importe_iva_10_5_porc,
                            'Importe IVA 21%': importe_iva_21_porc,
                            'Importe IVA 27%': importe_iva_27_porc,
                            'Importe No Gravado': importe_no_gravado,
                            'Importe Otros Tributos': importe_otros_tributos,
                            'Importe Total': importe_total,
                            'Importe de Impuestos Internos': importe_impuestos_internos,
                            'Importe de Impuestos Municipales': importe_impuestos_municipales,
                            'Importe de Per. o Pagos a Cta. de Otros Imp. Nac.': importe_percepciones_o_pagos_cta_otros_imp_nac,
                            'Importe de Percepciones de Ingresos Brutos': importe_percepciones_ing_brutos,
                            'Importe de Percepciones o Pagos a Cuenta de IVA': importe_percepciones_o_pago_cuenta_iva,
                            'Moneda Original': moneda_original,
                            'Nro. Doc. Vendedor': nro_doc_vendedor,
                            'Neto Gravado IVA 0%': neto_gravado_iva_0_porc,
                            'Neto Gravado IVA 2,5%': neto_gravado_iva_2_5_porc,
                            'Neto Gravado IVA 5%': neto_gravado_iva_5_porc,
                            'Neto Gravado IVA 10,5%': neto_gravado_iva_10_5_porc,
                            'Neto Gravado IVA 21%': neto_gravado_iva_21_porc,
                            'Neto Gravado IVA 27%': neto_gravado_iva_27_porc,
                            'Número de Comprobante': nro_comprobante,
                            'Punto de Venta': punto_de_venta,
                            'Tipo Doc. Vendedor': tipo_doc_vendedor,
                            'Tipo de Cambio': tipo_de_cambio,
                            'Tipo de Comprobante': tipo_de_comprobante,
                            'Total IVA': total_iva,
                            'Total Neto Gravado': total_neto_gravado,
                            ...otras_columnas
                        } = comprobante

                        fecha_emision = this.formatDate(fecha_emision)

                        return {
                            credito_fiscal_computable,
                            denominacion_vendedor,
                            fecha_emision,
                            importe_exento,
                            importe_iva_2_5_porc,
                            importe_iva_5_porc,
                            importe_iva_10_5_porc,
                            importe_iva_21_porc,
                            importe_iva_27_porc,
                            importe_no_gravado,
                            importe_otros_tributos,
                            importe_total,
                            importe_impuestos_internos,
                            importe_impuestos_municipales,
                            importe_percepciones_o_pagos_cta_otros_imp_nac,
                            importe_percepciones_ing_brutos,
                            importe_percepciones_o_pago_cuenta_iva,
                            moneda_original,
                            neto_gravado_iva_0_porc,
                            neto_gravado_iva_2_5_porc,
                            neto_gravado_iva_5_porc,
                            neto_gravado_iva_10_5_porc,
                            neto_gravado_iva_21_porc,
                            neto_gravado_iva_27_porc,
                            nro_doc_vendedor,
                            nro_comprobante,
                            punto_de_venta,
                            tipo_doc_vendedor,
                            tipo_de_cambio,
                            tipo_de_comprobante,
                            total_iva,
                            total_neto_gravado,
                            tipo_comp_cargado: 'PORTAL',
                            ...otras_columnas
                        }
                    })
                    this.validarColumnasPortal(datosParseados)
                    this.datos = datosParseados
                }, error: (error) => {
                    this.$store.dispatch("show_snackbar", {
                        text: "Error al parsear el archivo CSV:" + error,
                        color: "error",
                    });
                }
            });
        },
    },

    watch: {
        async archivo(val) {
            if (val.length > 0) {
                this.tipo_archivo_cargado = val[0].type
                if (val[0].type === 'text/csv') {
                    this.parseCSV(val[0]);

                }
            }
        },

        async dialog(val) {
            if (val) {
                this.filtro.empresa = null
                this.filtro.tipo = null
                this.archivo = []
            }
        },
    },

    async created() {
        await this.$store.dispatch("comprobantesAfip/getFamilias").then((res) => {
            if (res.exito) {
                this.empresas = res.data;

                this.empresas = this.empresas.filter((empresa) =>
                    this.familias.some((familia) => familia.id == empresa.Id)
                );

                this.empresas.forEach((empresa) => {
                    empresa.Cuit = empresa.Cuit.replace(/-/g, "");
                });

                if (this.empresas.length == 1) {
                    this.filtro.empresa = this.empresas[0].Id;
                }
            } else {
                this.$store.dispatch("show_snackbar", {
                    text: res.message,
                    color: "error",
                });
            }
        });
    },

    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        ...mapState(["familias"]),
    },

    props: {
        value: Boolean,
    },

    components: {
        FileDropzone,
        BtnConfirmar,
    },
};
</script>