<template>
  <v-dialog v-model="dialog" max-width="900" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Nuevo recibo combustible - {{ fecha }}</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="familias" @change="setEmpresas"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Marca
            <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="empresasFiltradas.filter(emp => emp.familia === data.familia)
                " @change="setSucursal"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete v-model="data.sucursal" item-text="provincia" item-value="id" hide-details outlined dense
              clearable :items="sucursalesFiltradas.filter(
                suc => suc.familia_id === data.familia
              )
                "></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="3" md="3" class="py-1 mt-2">
            <v-checkbox label="Voucher" v-model="data.voucher" class="mt-4" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4" class="py-1">
            Retira
            <v-text-field v-if="data.otro_retira" v-model.trim="data.retira" outlined hide-details dense></v-text-field>
            <v-autocomplete v-else v-model="data.retira" item-text="Nombre" item-value="Retira_id" hide-details outlined
              dense clearable
              :items="autorizados.filter(aut => aut.Familia_id === data.familia && aut.Empresa_id == data.empresa && aut.Sucursal_id == data.sucursal)"></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" md="2" class="py-1">
            <v-checkbox label="Otro" v-model="data.otro_retira" class="mt-5" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2" class="py-1" v-if="!data.voucher">
            Cantidad (litros)
            <v-text-field v-model.trim="data.litros" outlined type="number" dense hide-details
              :disabled="data.tanque_lleno"></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3" class="py-1"" v-if="!data.voucher">
            <v-checkbox label="Tanque lleno" v-model="data.tanque_lleno" class="mt-5" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1" v-if="!data.voucher">
            Tipo de combustible
            <v-autocomplete v-model="data.tipo_combustible" item-text="Nombre" item-value="Id" hide-details outlined
              dense clearable :items="tipos_combustible"></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="3" md="3" class="py-1">
            Sector
            <v-autocomplete v-model="data.sector" item-text="Nombre" item-value="Id" hide-details outlined dense
              clearable :items="sectores"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="py-1">
            Destino
            <!-- <v-text-field v-model.trim="data.destino" outlined hide-details dense></v-text-field> -->
            <v-autocomplete v-model="data.destino" item-text="Nombre" item-value="Empresa_comb_id" hide-details outlined
              dense clearable :items="destinos.filter(dest => dest.Sucursal_id == data.sucursal)"
              @change="setEmpresas"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" class="py-1">
            Vin
            <v-text-field v-model.trim="data.vin" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="py-1">
            Dominio
            <v-text-field v-model.trim="data.patente" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="py-1">
            Km
            <v-text-field v-model.trim="data.km" outlined type="number" hide-details dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="py-2 pb-5">
            Observación
            <v-textarea v-model.trim="data.observacion" rows="2" hide-details auto-grow outlined dense></v-textarea>
          </v-col>
          <v-col cols="12" sm="2" md="12" class="py-1 mb-3 mt-2">
            <div style="width: 100; display: flex; justify-content: end">
              <BtnConfirmar @action="guardar" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import moment from 'moment'
import { pdfOrdenDeCompra, pdfVoucherCombustible } from '../../../util/plantillas/pdfs';

export default {
  data() {
    return {
      load: false,
      fecha: moment.utc().format('DD/MM/YYYY'),
      sucursalesFiltradas: [],
      empresasFiltradas: [],
      mostrarImagen: false,
      urlImagenSeleccionada: '',
      nombreImagenSeleccionada: '',
      infoFamilias: [],
      infoSucursales: [],
      voucher: {},
      recibo: {},
      autorizados: [],
      destinos: [],

      pdf: {
        data: {},
        nombre: '',
        titulo: ''
      },

      plantilla: {},

      tipos_combustible: [],

      data: {
        familia: null,
        empresa: null,
        sucursal: null,
        //   fecha getdate
        //   periodo: getdate solo para filtros
        retira: '',
        sector: null,
        voucher: false,
        litros: null, // number
        tanque_lleno: false, // number lleno -1 si colocan check se anula en litros y se envia en el campo litros -1
        tipo_combustible: null, // tabla combustible
        vin: '', // codigo por si no tiene patente
        patente: '',
        km: null, // number
        //   numero_factura solo se usa cuando esta por ser auditado,
        //   monto cuando se audita
        monto: null,
        // fecha_pago cuando se audita
        //   inhabilitado: false
        destino: '',

        otro_retira: false,
        observacion: ''
      },

      imagenes: [],
    }
  },

  methods: {
    async guardar() {
      if (this.data.voucher) {
        // VALIDAR PARA VOUCHER
        if (
          this.data.empresa &&
          this.data.familia &&
          this.data.sucursal &&
          this.data.retira &&
          this.data.sector &&
          (this.data.vin.length > 0 || this.data.patente.length > 0)
        ) {

          this.$store.state.loading = true

          await this.$store
            .dispatch('vouchersCombustible/getVoucher', {
              familia: this.data.familia,
              empresa: this.data.empresa,
              sucursal: this.data.sucursal
            })
            .then(async res => {
              if (res.exito) {
                this.voucher = res.data
                if (this.voucher) {
                  // INSERTAR EL RECIBO QUE SE VA A GENERAR
                  const litros = this.data.tanque_lleno ? -1 : this.data.litros
                  const monto = this.voucher.Importe
                  await this.$store.dispatch('recibosCombustible/nuevoRecibo', {
                    familia: this.data.familia,
                    empresa: this.data.empresa,
                    sucursal: this.data.sucursal,
                    sector: this.data.sector,
                    voucher: this.data.voucher,
                    retira: this.data.retira,
                    litros,
                    tipo_combustible: this.data.tipo_combustible,
                    km: this.data.km,
                    monto,
                    imagen_es: this.voucher.Logo_estacion,
                    direccion_es: this.voucher.Direccion,
                    destino: this.data.destino,
                    vin: this.data.vin,
                    patente: this.data.patente,
                    retira_id: this.data.retira,
                    observacion: this.data.observacion
                  }).then(async res => {
                    if (res.exito) {
                      await this.$store.dispatch('vouchersCombustible/getPlantilla').then(res => {
                        if (res.exito) {
                          this.plantilla = res.data
                        } else {
                          this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                          })
                        }
                      })

                      this.recibo = res.data

                      const patente = this.recibo.Patente ? this.recibo.Patente : null
                      const vin = this.recibo.Vin ? this.recibo.Vin : null
                      const fecha = this.recibo.Fecha ? moment.utc(this.recibo.Fecha).format('DD/MM/YYYY') : null


                      const importe = `$${this.recibo.Monto}`
                      const numeroFormateado = this.recibo.Id.toString().padStart(8, '0');
                      const numero = `N° 0001 - ${numeroFormateado}`
                      const direccion = this.voucher.Direccion
                      this.pdf.data = await pdfVoucherCombustible(this.plantilla.url, this.voucher.Logo_empresa, this.voucher.Logo_familia, this.voucher.Logo_estacion, importe, numero, direccion, patente, vin, fecha)
                      this.pdf.titulo = `Voucher - ${numero}`
                      this.pdf.nombre = this.pdf.titulo
                      this.$emit('abrirModalVerPdf', this.pdf)
                      this.dialog = false

                    } else {
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                      })
                    }
                  })
                }
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
          this.$store.state.loading = false
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Los campos Empresa, Marca, Sucursal, Retira, Sector, Vin o Dominio son obligatorios',
            color: 'warning'
          })
          return
        }
      } else {
        // VALIDAR PARA ORDEN DE COMPRA
        if (
          this.data.empresa &&
          this.data.familia &&
          this.data.sucursal &&
          this.data.retira &&
          (this.data.sector ||
            this.data.destino > 0 || this.data.destino) &&
          (this.data.litros || this.data.tanque_lleno) &&
          this.data.tipo_combustible &&
          (this.data.vin.length > 0 || this.data.patente.length > 0)
        ) {

          // NUEVO RECIBO ORDEN DE COMPRA
          const litros = this.data.tanque_lleno ? -1 : this.data.litros

          this.$store.state.loading = true
          await this.$store.dispatch('recibosCombustible/nuevoRecibo', {
            familia: this.data.familia,
            empresa: this.data.empresa,
            sucursal: this.data.sucursal,
            sector: this.data.sector,
            voucher: this.data.voucher,
            retira: this.data.otro_retira ? this.data.retira : Number(this.data.retira),
            litros,
            tipo_combustible: this.data.tipo_combustible,
            km: this.data.km,
            destino: this.data.destino,
            vin: this.data.vin,
            patente: this.data.patente
          }).then(async res => {
            if (res.exito) {
              this.recibo = res.data

              // OBTENGO LA PLANTILLA DE LA ORDEN DE COMPRA
              await this.$store.dispatch('recibosCombustible/getPlantillaOrdenDeCompra').then(async res => {
                if (res.exito) {
                  this.plantilla = res.data
                } else {
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                  })
                }
                const numeroDeOrden = this.recibo.Id.toString().padStart(8, '0');
                const url = this.plantilla.url
                const fecha = moment.utc(this.recibo.Fecha).format('DD/MM/YYYY')

                const retira = this.data.otro_retira ? this.data.retira : this.autorizados.find(aut => aut.Retira_id == this.data.retira)?.Nombre

                const nombre_tipo_combustible = this.tipos_combustible.find(tip => tip.Id === this.recibo.Tipo_combustible_id)?.Nombre || null
                const tipo_combustible = nombre_tipo_combustible ? `Tipo de combustible: ${nombre_tipo_combustible}` : null

                let nombre_sector = this.sectores.find(sec => sec.Id === this.recibo.Sector_id)?.Nombre || null
                const sector = `Sector: ${nombre_sector}`

                const litros = this.recibo.Litros === -1 ? 'Tanque lleno' : `${this.recibo.Litros} Litros`

                const dominio = this.recibo.Patente ? `Dominio: ${this.recibo.Patente}` : null

                  const destino = this.recibo.Empresa_comb_id ? `${this.destinos.find(des => des.Empresa_comb_id === this.recibo.Empresa_comb_id)?.Nombre}` : null
                
                const vin = this.recibo.Vin ? `VIN: ${this.recibo.Vin}` : null
                const observaciones = this.recibo.Observaciones ? `Observación: ${this.recibo.Observaciones}` : null

                const infoDetalle = {
                  litros,
                  tipo_combustible,
                  sector,
                  dominio,
                  vin,
                  destino,
                  observaciones
                }


                let infoOrden = null
                await this.$store.dispatch('recibosCombustible/getInfoOrden', {
                  familia: this.data.familia,
                  sucursal: this.data.sucursal
                }).then(res => {
                  if (res.exito) {
                    infoOrden = res.data

                    let inicioActividad = null
                    res.data.Inicio_actividad ? inicioActividad = moment.utc(res.data.Inicio_actividad).format('MM/YYYY') : null

                    infoOrden = {
                      familia_id: res.data.familia_id,
                      razon_social: res.data.Razon_social || '',
                      cuit: res.data.Cuit || '',
                      ingresos_brutos: res.data.Ing_brutos || '',
                      inicio_actividad: inicioActividad,

                      logo_familia: res.data.Logo_familia,
                      logo_empresa: res.data.Logo_marca,
                      direccion_sucursal: res.data.Domicilio,
                      telefono: res.data.Telefono,

                      persona_autorizada: res.data.nombre_usuario,
                      observaciones: res.data.Observaciones
                    }

                  } else {
                    this.$store.dispatch('show_snackbar', {
                      text: res.message,
                      color: 'error'
                    })
                  }
                })
                this.pdf.data = await pdfOrdenDeCompra(url, fecha, numeroDeOrden, retira, infoOrden, infoDetalle)
                this.pdf.titulo = `Orden de compra - ${numeroDeOrden}`
                this.pdf.nombre = this.pdf.titulo
                this.$emit('abrirModalVerPdf', this.pdf)
                this.dialog = false
              })
            }
          })
          this.$store.state.loading = false

        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Los campos Empresa, Marca, Sucursal, Retira, Sector, Destino, cantidad ó tanque lleno, tipo de combustible, Vin o Dominio, y monto son obligatorios',
            color: 'warning'
          })
          return
        }

      }
    },

    setEmpresas() {
      const empresa = this.empresas.filter(
        emp => emp.familia === this.data.familia
      )
      empresa.length === 1 ? (this.data.empresa = empresa[0].id) : null
    },

    setSucursal() {
      const sucursal = this.sucursales.filter(
        suc => suc.familia_id === this.data.familia
      )
      sucursal.length === 1 ? (this.data.sucursal = sucursal[0].id) : null
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias', 'empresas']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean,
    sectores: Array
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.data = {
          familia: null,
          empresa: null,
          sucursal: null,
          //   fecha getdate
          //   periodo: getdate solo para filtros
          retira: '',
          sector: null,
          voucher: false,
          litros: null, // number
          tanque_lleno: false, // number lleno -1 si colocan check se anula en litros y se envia en el campo litros -1
          tipo_combustible: null, // tabla combustible
          vin: '', // codigo por si no tiene patente
          patente: '',
          km: null, // number
          //   numero_factura solo se usa cuando esta por ser auditado,
          //   monto cuando se audita
          monto: null,
          // fecha_pago cuando se audita
          //   inhabilitado: false
          destino: ''
        }
      } else {
        this.empresasFiltradas = this.empresas
        this.sucursalesFiltradas = this.sucursales

        this.$store.state.loading = true
        await this.$store
          .dispatch('recibosCombustible/getTiposCombustible')
          .then(res => {
            if (res.exito) {
              this.tipos_combustible = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'warning'
              })
            }
          })

        await this.$store.dispatch('recibosCombustible/getAutorizadosHabilitados').then(res => {
          if (res.exito) {
            this.autorizados = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })

        await this.$store.dispatch('recibosCombustible/getDestinos').then(res => {
          if (res.exito) {
            this.destinos = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
        this.$store.state.loading = false
      }
    },

    'data.imagen'(val) {
      if (val) {
        this.mostrarImagen = true
        this.nombreImagenSeleccionada = val.nombre
        this.urlImagenSeleccionada = val.url
      } else {
        this.urlImagenSeleccionada = ''
      }
    },

    'data.tanque_lleno'(val) {
      if (val) {
        this.data.litros = null
      }
    },

    async 'data.voucher'(val) {
      if (val) {
        this.data.litros = null
        this.data.tanque_lleno = false
        this.data.tipo_combustible = null
      }
    },

    'data.otro_retira'(val) {
      this.data.retira = null
    }
  },

  created() {
    if (this.familias.length == 1) this.data.familia = this.familias[0].id
    this.sucursalesFiltradas = this.sucursales
    this.empresasFiltradas = this.empresas
  },

  components: {
    TextFieldMoney,
    BtnConfirmar,
  }
}
</script>
