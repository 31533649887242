<template>
    <v-dialog v-model="dialog" max-width="900" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>Editar recibo N° {{ reciboData.numero }}</h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
                    <v-col cols="12" sm="3" md="3" class="py-1">
                        Empresa
                        <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined
                            dense clearable :items="familias" @change="setEmpresas" readonly disabled></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-1">
                        Marca
                        <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id" readonly disabled
                            hide-details outlined dense clearable :items="empresasFiltradas.filter(emp => emp.familia === data.familia)
                                " @change="setSucursal"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-1">
                        Sucursal
                        <v-autocomplete v-model="data.sucursal" item-text="provincia" item-value="id" readonly disabled
                            hide-details outlined dense clearable :items="sucursalesFiltradas.filter(
                                suc => suc.familia_id === data.familia
                            )
                                "></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="3" md="3" class="py-1 mt-2">
                        <v-checkbox label="Voucher" v-model="data.voucher" class="mt-4" hide-details readonly
                            disabled></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-1">
                        Retira
                        <v-text-field v-if="data.otro_retira" v-model.trim="data.retira" outlined hide-details
                            dense></v-text-field>
                        <v-autocomplete v-else v-model="data.retira" item-text="Nombre" item-value="Retira_id"
                            hide-details outlined dense clearable
                            :items="autorizados.filter(aut => aut.Familia_id === data.familia && aut.Empresa_id == data.empresa && aut.Sucursal_id == data.sucursal)"></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" class="py-1"" v-if="!data.voucher">
                        <v-checkbox label="Otro" v-model="data.otro_retira" class="mt-5" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="2" class="py-1" v-if="!data.voucher">
                        Cantidad (litros)
                        <v-text-field v-model.trim="data.litros" outlined type="number" dense hide-details
                            :disabled="data.tanque_lleno"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3" md="3" class="py-1"" v-if="!data.voucher">
                        <v-checkbox label="Tanque lleno" v-model="data.tanque_lleno" class="mt-5"
                            hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1" v-if="!data.voucher">
                        Tipo de combustible
                        <v-autocomplete v-model="data.tipo_combustible" item-text="Nombre" item-value="Id" hide-details
                            outlined dense clearable :items="tipos_combustible"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="3" md="3" class="py-1">
                        Sector
                        <v-autocomplete v-model="data.sector" item-text="Nombre" item-value="Id" hide-details outlined
                            dense clearable :items="sectores"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-1">
                        Destino
                        <v-autocomplete v-model="data.destino" item-text="Nombre" item-value="Retira_id" hide-details
                            outlined dense clearable
                            :items="destinos.filter(dest => dest.Sucursal_id == data.sucursal)"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" class="py-1">
                        Vin
                        <v-text-field v-model.trim="data.vin" outlined hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="py-1">
                        Dominio
                        <v-text-field v-model.trim="data.patente" outlined hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="py-1">
                        Km
                        <v-text-field v-model.trim="data.km" outlined type="number" hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12"
                        class="py-2 pb-5">
                        Observación
                        <v-textarea v-model.trim="data.observacion" rows="2" hide-details auto-grow outlined 
                             dense></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="2" md="12" class="py-1 mb-3 mt-2">
                        <div style="width: 100; display: flex; justify-content: end">
                            <BtnConfirmar @action="editar" />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
    data() {
        return {
            load: false,
            sucursalesFiltradas: [],
            empresasFiltradas: [],
            urlImagenSeleccionada: '',
            nombreImagenSeleccionada: '',
            infoFamilias: [],
            infoSucursales: [],
            reciboData: {
                numero: 0,
                familia: null,
                marca: null,
                sucursal: null,
                voucher: false,
                retira: '',
                litros: null,
                tanque_lleno: false,
                tipo_combustible: null,
                sector: null,
                destino: '',
                vin: '',
                patente: '',
                km: null
            },

            pdf: {
                data: {},
                nombre: '',
                titulo: ''
            },

            plantilla: {},

            tipos_combustible: [],

            data: {
                familia: null,
                empresa: null,
                sucursal: null,
                retira: '',
                sector: null,
                voucher: false,
                litros: null,
                tanque_lleno: false,
                tipo_combustible: null,
                vin: '',
                patente: '',
                km: null,
                monto: null,
                destino: '',
                otro_retira: false,
                observacion: ''
            },

            imagenes: [],
            autorizados: [],
            destinos: [],
        }
    },

    methods: {
        async editar() {

            // VALIDAR QUE LOS CAMPOS RETIRA, Y DEMAS SEAN VÁLIDOS
            if (this.data.voucher) {

                // VALIDAR PARA VOUCHER

            } else {

                // VALIDAR PARA ORDEN DE COMPRA

            }

            const litros = this.data.tanque_lleno ? -1 : this.data.litros

            this.$store.state.loading = true
            await this.$store.dispatch('recibosCombustible/editarRecibo', {
                recibo_id: this.recibo.Id,
                retira: this.data.retira,
                sector: this.data.sector,
                destino: this.data.destino,
                vin: this.data.vin,
                patente: this.data.patente,
                km: this.data.km,
                litros,
                tipo_combustible: this.data.tipo_combustible,
                observacion: this.data.observacion
            }).then(res => {
                if (res.exito) {
                    this.dialog = false
                    this.$swal.fire({
                        icon: 'success',
                        title: res.message,
                    })

                    this.$emit('actualizar', true)
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })

            this.$store.state.loading = false
        },

        setEmpresas() {
            const empresa = this.empresas.filter(
                emp => emp.familia === this.data.familia
            )
            empresa.length === 1 ? (this.data.empresa = empresa[0].id) : null
        },

        setSucursal() {
            const sucursal = this.sucursales.filter(
                suc => suc.familia_id === this.data.familia
            )
            sucursal.length === 1 ? (this.data.sucursal = sucursal[0].id) : null
        },
    },

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        ...mapState(['familias', 'empresas']),
        ...mapState('genericos', ['sucursales'])
    },

    props: {
        value: Boolean,
        sectores: Array,
        recibo: Object
    },

    watch: {
        async dialog(val) {
            if (!val) {
                this.data = {
                    familia: null,
                    empresa: null,
                    sucursal: null,
                    retira: '',
                    sector: null,
                    voucher: false,
                    litros: null,
                    tipo_combustible: null,
                    vin: '',
                    patente: '',
                    km: null,
                    monto: null,
                    destino: ''
                }
            } else {

                this.$store.state.loading = true
                await this.$store.dispatch('recibosCombustible/getAutorizadosHabilitados').then(res => {
                    if (res.exito) {
                        this.autorizados = res.data
                    } else {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                        })
                    }
                })
                await this.$store.dispatch('recibosCombustible/getDestinos').then(res => {
                    if (res.exito) {
                        this.destinos = res.data
                    } else {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                        })
                    }
                })
                this.empresasFiltradas = this.empresas
                this.sucursalesFiltradas = this.sucursales

                let numeroFormateado = 0
                if (this.recibo.Voucher) {
                    numeroFormateado = `0001-${this.recibo.Id.toString().padStart(8, '0')}`
                } else {
                    numeroFormateado = this.recibo.Id.toString().padStart(8, '0');
                }

                await this.$store
                    .dispatch('recibosCombustible/getTiposCombustible')
                    .then(res => {
                        if (res.exito) {
                            this.tipos_combustible = res.data
                        } else {
                            this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'warning'
                            })
                        }
                    })
                // GET EMPRESAS Y FAMILIAS PARA VER LOS DETALLES  

                this.reciboData.numero = numeroFormateado
                this.data.familia = this.recibo.Familia_id
                this.data.empresa = this.recibo.Empresa_id
                this.data.sucursal = this.recibo.Sucursal_id
                this.data.voucher = this.recibo.Voucher
                this.data.retira = this.recibo.Retira
                // this.data.retira = this.recibo.Retira_id ? : 
                if (this.recibo.Retira_id) {
                    // Quitar el check otro
                    this.data.retira = this.autorizados.find(aut => aut.Retira_id == this.recibo.Retira_id)?.Retira_id
                }


                this.data.litros = this.recibo.Litros === -1 ? null : this.recibo.Litros
                this.data.tanque_lleno = this.recibo.Litros === -1 ? true : false
                this.data.tipo_combustible = this.recibo.Tipo_combustible_id
                this.data.sector = this.recibo.Sector_id
                this.data.destino = this.destinos.find(dest => dest.Empresa_comb_id === this.recibo.Empresa_comb_id)
                this.data.vin = this.recibo.Vin
                this.data.patente = this.recibo.Patente
                this.data.km = this.recibo.Km

                this.data.observacion = this.recibo.Observaciones    
                this.$store.state.loading = false
            }
        },

        'data.tanque_lleno'(val) {
            if (val) {
                this.data.litros = null
            }
        },

        async 'data.voucher'(val) {
            if (val) {
                this.data.litros = null
                this.data.tanque_lleno = false
                this.data.tipo_combustible = null
            }
        },

        'data.otro_retira'(val) {
            this.data.retira = null
        }
    },

    created() {
        if (this.familias.length == 1) this.data.familia = this.familias[0].id
        this.sucursalesFiltradas = this.sucursales
        this.empresasFiltradas = this.empresas
    },

    components: {
        TextFieldMoney,
        BtnConfirmar,
    }
}
</script>