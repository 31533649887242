var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',[_vm._v("Anular factura "+_vm._s(_vm.factura?.Factura))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" N° Factura Nota de Crédito "),_c('v-text-field-simplemask',{attrs:{"properties":{
                        'hide-details': true,
                        outlined: true,
                        dense: true,
                        placeholder: '####-########',
                        hideDetails: true
                    },"options":{
                        inputMask: '####-########',
                        outputMask: '####-########',
                        applyAfter: false,
                        alphanumeric: true,
                        empty: ''
                    }},model:{value:(_vm.data.factura),callback:function ($$v) {_vm.$set(_vm.data, "factura", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.factura"}})],1),_c('v-col',{staticClass:"py-0 mt-2 mb-3",attrs:{"cols":"12","sm":"2","md":"12"}},[_c('div',{staticStyle:{"width":"100","display":"flex","justify-content":"end"}},[_c('BtnConfirmar',{attrs:{"icono":"fas fa-ban","nombre":"Anular","color":"error"},on:{"action":_vm.anular}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }