<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn v-if="permiso_portal_iva" style="position: absolute; top: -15px; right: 105px; z-index: 1"
              color="info" small fab title="Importar Portal IVA" @click="abrirModalPortal">
              <v-icon>fas fa-file-csv</v-icon>
            </v-btn>
            <v-btn v-if="permiso_comprobantes_recibidos" style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success" small fab title="Importar comprobantes" @click="abrir_modal">
              <v-icon>fas fa-file-upload</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Empresa
                    <v-autocomplete v-model="filtro.empresa" item-text="Nombre" return-object hide-details outlined
                      dense :items="empresas" @change="setProveedoresTipos"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha desde
                    <FechaPicker v-model="filtro.fecha_desde" hide-details clearable />
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha hasta
                    <FechaPicker v-model="filtro.fecha_hasta" hide-details clearable />
                  </v-col>

                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Proveedor
                    <v-autocomplete v-model="filtro.proveedor" item-text="proveedor" return-object hide-details outlined
                      dense clearable :items="proveedores" @change="setTipos"></v-autocomplete>
                  </v-col>
                  <v-col cols="0" sm="3" md="3" class="py-1"> </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-2">
                    Tipo de factura
                    <v-autocomplete v-model="filtro.tipo" item-text="Tipo" return-object hide-details outlined dense
                      clearable :items="tiposFiltrados"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-2">
                    Estado
                    <v-autocomplete v-model="filtro.estado" item-text="nombre_estado" return-object hide-details
                      outlined dense clearable :items="estados"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-2">
                    <div style="
                        font-weight: 500;
                        line-height: 1.5;
                        display: flex;
                        align-items: center;
                        height: 100%;
                      ">
                      <v-switch v-model="filtro.sobrantes" label="Incluir sobrantes" class="d-flex align-center pl-2"
                        hide-details dense></v-switch>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="2" md="12" class="pt-0" style="margin-top: -22px">
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mt-2">
          <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers"
            :items="facturasFiltradas" :loading="load" sort-desc dense :search="search"
            @contextmenu:row="click_derecho">
            <template v-slot:top>
              <v-row class="d-flex pa-2 h-mobile" style="justify-content: space-between; height: 4.3rem" no-gutters
                hide-details>
                <v-card-title style="margin: 0 0 !important" class="py-2">
                  Diferencias
                </v-card-title>
                <v-col cols="12" sm="3" class="py-2">
                  <div style="display: flex; align-items: center; gap: 10px">
                    <SearchDataTable v-model="search" />
                    <v-btn @click="reprocesarFiltrados" icon color="primary" dark title="Reprocesar los filtrados.">
                      <v-icon color="
                       green
                      ">
                        fas fa-sync-alt
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <div class="mt-8 pb-3" v-if="$vuetify.breakpoint.mobile"></div>
              <v-divider class=""></v-divider>
            </template>
            <template v-slot:footer>
              <v-row class="d-flex h-mobile" style="justify-content: end" no-gutters hide-details>
                <v-col cols="12" sm="2 " class="py-4 px-4">
                  <v-text-field-money label="Total" v-bind:properties="{
                    prefix: '$',
                    type: 'tel',
                    outlined: true,
                    dense: true,
                    hideDetails: true,
                    readonly: true
                  }" v-bind:options="{
                    locale: 'es-AR',
                    precision: 2,
                    empty: null,
                  }" v-model="total" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.Tipo_comp_cargado`]="{ item }">
              <v-chip v-if="item.Tipo_comp_cargado" color="yellow"
              text-color="black"
              small>
                {{ item.Tipo_comp_cargado }}
              </v-chip>
            </template>
            <template v-slot:[`item.Denominacion_emisor`]="{ item }">
              <span style="text-overflow: ellipsis ellipsis;" :style="item.Denominacion_emisor.length > 30
                ? 'font-size: 10px;'
                : 'font-size: 12px;'
                ">
                {{ item.Denominacion_emisor }}
              </span>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip :disabled="!item.Comentario" right :color="'indigo darken-1'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon small :color="item.Comentario ? 'indigo lighten-0' : 'grey darken-2'
                      ">
                      fas fa-comment-dots
                    </v-icon>
                  </v-btn>
                </template>
                <div style="
                    font-weight: 600;
                    padding: 10px 18px;
                    display: flex;
                    flex-direction: column;
                  ">
                  <h2 class="pb-2">Comentarios</h2>
                  <span>
                    {{ item.Comentario }}
                  </span>
                </div>
              </v-tooltip>
              <v-btn @click="reprocesarFactura(item)" icon title="Reprocesar" dark :disabled="(item.Estado == 1 && item.Comentario == null) ||
                item.Estado == 4
                ">
                <v-icon small color="green lighten-1"> fas fa-redo </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <div style="
            width: 100%;
            margin-top: 0.6rem;
            display: flex;
            justify-content: end;
            gap: 1rem;
          " class="pb-1">
          <BtnConfirmar nombre="Descargar" color="success" texto="¿Desea descargar los comprobantes?"
            @action="descargar()" icono="fas fa-file-download" />
        </div>
      </v-col>
      <v-menu v-model="mostrarMenu" :position-x="x" :position-y="y" absolute offset-y>
        <v-list>
          <v-list-item @click="abrirModalAnular()">
            <v-list-item-title>
              <v-icon color="error" small left>fas fa-ban</v-icon>
              Anular Factura
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <ModalSubirComprobantes v-model="modal" :tipos_comprobantes="tipos_comprobantes" />
    <PortalIVA v-model="modalPortalIVA" />
    <AnularFactura v-model="modalAnular" :factura="facturaSeleccionada" />
  </div>
</template>
<script>
import BtnFiltro from "../../components/util/BtnFiltro.vue";
import ModalSubirComprobantes from "../../components/administracion/ModalSubirComprobantes.vue";
import { mapState } from "vuex";
import { format_money, rellenarConCeros } from "../../util/utils";
import BtnConfirmar from "../../components/util/BtnConfirmar.vue";
import { utils, write } from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";
import FechaPicker from "../../components/util/FechaPicker.vue";
import SearchDataTable from "../../components/util/SearchDataTable.vue";
import PortalIVA from "../../components/administracion/PortalIVA.vue";
import AnularFactura from "../../components/administracion/comprobantes/AnularFactura.vue";

export default {
  data() {
    return {
      panel: 0,
      datos: [],
      items: [],
      modal: false,
      modalPortalIVA: false,
      modalAnular: false,

      x: 0,
      y: 0,
      mostrarMenu: false,

      load: false,
      search: "",
      show: false,

      empresas: [],
      proveedores: [],
      tipos: [],
      tiposFiltrados: [],

      facturas: [],
      facturasFiltradas: [],
      facturasTemp: [],
      estados: [],
      tipos_comprobantes: [],

      filtro: {
        empresa: null,
        fecha_desde: "",
        fecha_hasta: "",
        estado: null,
        tipo: null,
        proveedor: null,
        sobrantes: false,
      },

      total: null,

      permiso_portal_iva: false,
      permiso_comprobantes_recibidos: false,

      headers: [
        { text: "Proveedor", value: "Denominacion_emisor", width: "300px" },
        { text: "CUIT/CUIL", value: "Nro_doc_emisor_format" },
        { text: "Factura", value: "Factura" },
        { text: "Origen", value: "Tipo_comp_cargado" },

        { text: "CAE", value: "Cod_autorizacion" },
        { text: "Fecha", value: "Fecha_format" },
        { text: "Total", value: "Imp_total_format" },
        { text: "Estado", value: "estadoNombre" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          align: "center",
        },
      ],

      facturaSeleccionada: null,
    };
  },
  methods: {
    limpiarFiltro() {
      this.filtro.empresa = {};
      this.filtro.fecha_desde = "";
      this.filtro.fecha_hasta = "";
      this.filtro.estado = null;
      this.filtro.tipo = {};
      this.filtro.proveedor = {};
      this.filtro.sobrantes = false;
      this.total = null

      this.facturasFiltradas = [];
      this.proveedores = [];
      this.tipos = [];

      this.filtro.fecha_desde.toString();
      this.filtro.fecha_hasta.toString();
    },

    abrir_modal() {
      this.modal = true;
    },

    abrirModalPortal() {
      this.modalPortalIVA = true
    },

    obtenerNumeroTipoFactura(tipo) {
      if (Number(tipo)) {
        return Number(tipo)
      }
      const indiceSeparador = tipo.indexOf(" - ");
      if (indiceSeparador === -1) {
        return null;
      }
      const numeroStr = tipo.substring(0, indiceSeparador);
      const numero = parseInt(numeroStr, 10);

      if (isNaN(numero)) {
        return null;
      }

      return numero;
    },

    async buscar() {
      this.filtro.fecha_desde == null ? (this.filtro.fecha_desde = "") : null;
      this.filtro.fecha_hasta == null ? (this.filtro.fecha_hasta = "") : null;

      if (
        this.filtro.empresa &&
        (this.filtro.fecha_desde.length > 0 ||
          this.filtro.fecha_hasta.length > 0)
      ) {
        this.$store.state.loading = true;

        await this.$store
          .dispatch("comprobantesAfip/getComprobantes", {
            empresa: this.filtro.empresa.Id,
            fecha_desde: this.filtro.fecha_desde,
            fecha_hasta: this.filtro.fecha_hasta,
            sobrantes: this.filtro.sobrantes,
          })
          .then((res) => {
            if (res.exito) {
              this.facturas = res.data;

              this.facturas.forEach((factura) => {
                factura.estadoNombre = this.estados.find(
                  (estado) => estado.id == factura.Estado
                )?.nombre_estado;

                factura.orden = this.estados.find(
                  (estado) => estado.id == factura.Estado
                )?.orden;

                if (factura.Estado != 4) {
                  factura.permite_anular = this.tipos_comprobantes.find(tipo => tipo.Id === Number(factura.Tipo.split(' ')[0]))?.Permite_anular === 1 ? true : false
                }
              });

              this.facturasFiltradas = this.facturas;

              this.facturasFiltradas.forEach((factura) => {
                if (factura.Tipo != undefined) {
                  factura.NroFactura = this.obtenerNumeroTipoFactura(
                    factura.Tipo
                  );
                } else {
                  factura.Tipo = this.tipos_comprobantes.find(
                    (tipo) =>
                      tipo.sai_letra == factura.Letra &&
                      tipo.sai_tipo_documento == factura.Tipo_documento
                  )?.Tipo;
                  factura.NroFactura = this.tipos_comprobantes.find(
                    (tipo) =>
                      tipo.sai_letra == factura.Letra &&
                      tipo.sai_tipo_documento == factura.Tipo_documento
                  )?.Id;
                }
                if (factura.Letra == undefined) {
                  factura.Letra = this.tipos_comprobantes.find(
                    (tipo) => tipo.Id == factura.NroFactura
                  )?.sai_letra;
                }
                if (factura.Tipo_documento == undefined) {
                  factura.Tipo_documento = this.tipos_comprobantes.find(
                    (tipo) => tipo.Id == factura.NroFactura
                  )?.sai_tipo_documento;
                }

                factura.Punto_de_venta_format = rellenarConCeros(
                  factura.Punto_de_venta,
                  4
                );
                factura.Numero_desde_format = rellenarConCeros(
                  factura.Numero_desde,
                  8
                );
                factura.Factura = `${factura.Tipo_documento} ${factura.Letra} ${factura.Punto_de_venta_format}-${factura.Numero_desde_format}`;
                factura.Imp_total_format = format_money(factura.Imp_total);

                factura.Nro_doc_emisor_format = this.formatearCUIT(
                  factura.Nro_doc_emisor
                );

                factura.Fecha_format = moment
                  .utc(factura.Fecha)
                  .format("DD/MM/YYYY");
              });

              this.filtro.proveedor
                ? this.filtro.proveedor
                : (this.filtro.proveedor = {});
              this.filtro.tipo ? this.filtro.tipo : (this.filtro.tipo = {});
              this.filtro.estado
                ? this.filtro.estado
                : (this.filtro.estado = {});

              const primerCaso =
                Object.keys(this.filtro.proveedor).length > 0 &&
                Object.keys(this.filtro.tipo).length > 0 &&
                Object.keys(this.filtro.estado).length > 0;

              const segundoCaso =
                Object.keys(this.filtro.proveedor).length > 0 &&
                Object.keys(this.filtro.tipo).length == 0 &&
                Object.keys(this.filtro.estado).length == 0;

              const tercerCaso =
                Object.keys(this.filtro.proveedor).length > 0 &&
                Object.keys(this.filtro.tipo).length == 0 &&
                Object.keys(this.filtro.estado).length > 0;

              const cuartoCaso =
                Object.keys(this.filtro.proveedor).length == 0 &&
                Object.keys(this.filtro.tipo).length > 0 &&
                Object.keys(this.filtro.estado).length == 0;

              const quintoCaso =
                Object.keys(this.filtro.proveedor).length == 0 &&
                Object.keys(this.filtro.tipo).length == 0 &&
                Object.keys(this.filtro.estado).length > 0;

              const sextoCaso =
                Object.keys(this.filtro.proveedor).length > 0 &&
                Object.keys(this.filtro.tipo).length > 0 &&
                Object.keys(this.filtro.estado).length == 0;

              const septimoCaso =
                Object.keys(this.filtro.proveedor).length == 0 &&
                Object.keys(this.filtro.tipo).length > 0 &&
                Object.keys(this.filtro.estado).length > 0;

              if (primerCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchEstado = factura.Estado == this.filtro.estado.id;
                  const matchProveedor =
                    factura.Nro_doc_emisor ==
                    this.filtro.proveedor.nro_doc_emisor;
                  const matchTipo =
                    factura.NroFactura == this.filtro.tipo.Numero;

                  return matchEstado && matchProveedor && matchTipo;
                });
              }

              if (segundoCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchProveedor =
                    factura.Nro_doc_emisor ==
                    this.filtro.proveedor.nro_doc_emisor;
                  return matchProveedor;
                });
              }

              if (tercerCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchEstado = factura.Estado == this.filtro.estado.id;
                  const matchProveedor =
                    factura.Nro_doc_emisor ==
                    this.filtro.proveedor.nro_doc_emisor;
                  return matchEstado && matchProveedor;
                });
              }

              if (cuartoCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchTipo =
                    factura.NroFactura == this.filtro.tipo.Numero;

                  return matchTipo;
                });
              }

              if (quintoCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchEstado = factura.Estado == this.filtro.estado.id;
                  return matchEstado;
                });
              }

              if (sextoCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchProveedor =
                    factura.Nro_doc_emisor ==
                    this.filtro.proveedor.nro_doc_emisor;
                  const matchTipo =
                    factura.NroFactura == this.filtro.tipo.Numero;

                  return matchProveedor && matchTipo;
                });
              }

              if (septimoCaso) {
                this.facturasFiltradas = this.facturas.filter((factura) => {
                  const matchEstado = factura.Estado == this.filtro.estado.id;
                  const matchTipo =
                    factura.NroFactura == this.filtro.tipo.Numero;

                  return matchEstado && matchTipo;
                });
              }

              this.facturasFiltradas.sort((a, b) => a.orden - b.orden);

            }
          });

        this.total = this.facturasFiltradas.reduce((acc, factura) => {
          if (factura.Imp_total && factura.Estado != 5) {
            const importe = parseFloat(factura.Imp_total);
            return acc + importe;
          } else {
            return acc;
          }
        }, 0);

        this.$store.state.loading = false;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "Debes seleccionar una empresa y periódo.",
          color: "warning",
        });
      }
    },

    async getProveedores() {
      this.$store.state.loading = true;

      this.proveedores = [];

      await this.$store
        .dispatch("comprobantesAfip/getComprobantes", {
          empresa: this.filtro.empresa.Id,
          fecha_desde: this.filtro.fecha_desde,
          fecha_hasta: this.filtro.fecha_hasta,
          sobrantes: false,
        })
        .then((res) => {
          if (res.exito) {
            this.facturasTemp = res.data;
            let proveedores = [];
            proveedores = res.data.map((factura) => {
              return {
                proveedor: factura.Denominacion_emisor,
                nro_doc_emisor: factura.Nro_doc_emisor,
              };
            });

            this.proveedores = Array.from(
              new Set(proveedores.map(JSON.stringify))
            ).map(JSON.parse);
          }
        });
      this.$store.state.loading = false;
    },

    setTipos() {
      this.filtro.proveedor
        ? this.filtro.proveedor
        : (this.filtro.proveedor = {});
      if (Object.keys(this.filtro.proveedor).length > 0) {
        this.tiposFiltrados = this.tipos.filter((tipo) => {
          const facturaEncontrada = this.facturasTemp.find(
            (factura) =>
              factura.Nro_doc_emisor == this.filtro.proveedor.nro_doc_emisor
          );
          let tipoFactura = null;
          if (facturaEncontrada.Tipo != undefined) {
            tipoFactura = this.obtenerNumeroTipoFactura(facturaEncontrada.Tipo);
          }

          return tipo.Numero == tipoFactura;
        });
      }
    },

    async getTiposPorComprobantesExistentes() {
      let tipos = this.tipos_comprobantes.map((tipo) => {
        let tipoFactura = null;
        let numeroTipoFactura = 0;
        this.facturasTemp.forEach((factura) => {
          let numeroFactura = 0;
          if (factura.Tipo != undefined) {
            numeroFactura = this.obtenerNumeroTipoFactura(factura.Tipo);
            if (tipo.Id == numeroFactura) {
              tipoFactura = tipo.Tipo;
              numeroTipoFactura = tipo.Id;
            }
          } else {
            numeroFactura = this.tipos_comprobantes.find(
              (tipoc) =>
                tipoc.sai_letra == factura.Letra &&
                tipoc.sai_tipo_documento == factura.Tipo_documento
            )?.Id;

            if (tipo.Id == numeroFactura) {
              tipoFactura = tipo.Tipo;
              numeroTipoFactura = tipo.Id;
            }
          }
        });
        return {
          Tipo: tipoFactura,
          Numero: numeroTipoFactura,
        };
      });

      this.tipos = Array.from(new Set(tipos.map(JSON.stringify))).map(
        JSON.parse
      );
      this.tipos = this.tipos.filter(
        (tipoNotNull) => tipoNotNull.Tipo != null || tipoNotNull.Numero != 0
      );
      this.tiposFiltrados = this.tipos;
    },

    formatearCUIT(numero) {
      let numeroStr = numero.toString();
      if (numeroStr.length !== 11) {
        throw new Error("El número debe tener 11 dígitos");
      }
      let parte1 = numeroStr.slice(0, 2);
      let parte2 = numeroStr.slice(2, 10);
      let parte3 = numeroStr.slice(10);
      return `${parte1}-${parte2}-${parte3}`;
    },

    async reprocesarFactura(item) {
      this.$store.state.loading = true;
      await this.$store
        .dispatch("comprobantesAfip/procesarDatos", {
          reprocesar: true,
          factura: item,
        })
        .then((res) => {
          if (res.exito) {
            let text = "";
            this.facturasFiltradas.forEach((factura) => {
              if (factura.Cod_autorizacion == res.data.Cod_autorizacion) {
                if (
                  factura.Comentario == res.data.Comentario &&
                  factura.Estado == res.data.Estado
                ) {
                  text = " Y no hubo cambios en la misma.";
                } else {
                  factura.Comentario = res.data.Comentario;
                  factura.Estado = res.data.Estado;
                  factura.estadoNombre = this.estados.find(
                    (estado) => estado.id == factura.Estado
                  )?.nombre_estado;
                }
              }
            });

            this.$store.dispatch("show_snackbar", {
              text: res.message + text,
              color: "success",
            });
          } else {
            this.$store.dispatch("show_snackbar", {
              text: res.message,
              color: "error",
            });
          }
        });
      this.$store.state.loading = false;
    },

    async reprocesarFiltrados() {
      if (this.facturasFiltradas.length > 0) {
        const facturas = this.facturasFiltradas.filter((factura) => {
          return !(factura.Estado == 1 || factura.Estado == 4);
        });
        this.$store.state.loading = true;
        await this.$store
          .dispatch("comprobantesAfip/reprocesarFiltrados", {
            facturas: facturas,
            reprocesar: true,
          })
          .then((res) => {
            if (res.exito) {
              this.$store.dispatch("show_snackbar", {
                text: res.message,
                color: "success",
              });
              this.buscar();
            } else {
              this.$store.dispatch("show_snackbar", {
                text: res.message,
                color: "error",
              });
            }
          });
        this.$store.state.loading = false;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "Debes filtrar en la tabla antes de reprocesar todos los filtrados.",
          color: "warning",
        });
      }
    },

    descargar() {
      if (this.facturasFiltradas.length > 0) {
        const cabecera = [
          [
            "Proveedor",
            "CUIT/CUIL",
            "Factura",
            "Fecha",
            "CAE",
            "Imp total",
            "Estado",
            "Comentario",
          ],
        ];

        const ws = utils.aoa_to_sheet(cabecera);
        const array = [];

        this.facturasFiltradas.forEach((factura) => {
          let data = {
            proveedor: factura.Denominacion_emisor,
            Nro_doc_emisor: factura.Nro_doc_emisor_format,
            tipo_factura: factura.Factura,
            fecha: factura.Fecha_format,
            cae: factura.Cod_autorizacion,
            total: factura.Imp_total_format,
            estado: factura.estadoNombre,
            comentario: factura.Comentario,
          };
          array.push(data);
        });

        utils.sheet_add_json(ws, array, {
          header: [
            "proveedor",
            "Nro_doc_emisor",
            "tipo_factura",
            "fecha",
            "cae",
            "total",
            "estado",
            "comentario",
          ],
          skipHeader: true,
          origin: -1,
        });

        const columnas = Object.keys(array[0]);
        columnas.forEach((columna) => {
          const maxLength = array.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          );
          ws["!cols"] = ws["!cols"] || [];
          ws["!cols"].push({ wch: maxLength + 2 });
        });

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Reporte");
        const wbout = write(wb, { bookType: "xlsx", type: "array" });
        let name = "Comprobantes_" + moment().format("DD/MM/YYYY") + ".xlsx";
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          name
        );
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "Debes filtrar en la tabla antes de descargar el reporte.",
          color: "warning",
        });
      }
    },

    setProveedoresTipos() {
      this.tipos = [];
      this.proveedores = [];
    },

    abrirModalAnular() {
      this.modalAnular = true
    },

    click_derecho(event, item) {
      event.preventDefault()
      this.mostrarMenu = false
      this.x = event.clientX
      this.y = event.clientY

      if (item) {
        this.$nextTick(() => {
          this.facturaSeleccionada = JSON.parse(JSON.stringify(item.item))
          if (this.facturaSeleccionada.permite_anular) {
            this.mostrarMenu = true
          }
        })
      } else {
        this.$nextTick(() => {
          this.mostrarMenu = true
          this.facturaSeleccionada = this.expanded[0]
        })
      }
    },
  },

  computed: {
    ...mapState(["familias"]),
  },
  async created() {
    await this.$store.dispatch("comprobantesAfip/getEstados").then((res) => {
      if (res.exito) {
        this.estados = res.data;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }
    });

    await this.$store
      .dispatch("comprobantesAfip/getTiposComprobantes")
      .then((res) => {
        if (res.exito) {
          this.tipos_comprobantes = res.data;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: res.message,
            color: "error",
          });
        }
      });

    await this.$store.dispatch("comprobantesAfip/getFamilias").then((res) => {
      if (res.exito) {
        this.empresas = res.data;

        this.empresas = this.empresas.filter((empresa) =>
          this.familias.some((familia) => familia.id == empresa.Id)
        );

        if (this.empresas.length == 1) {
          this.filtro.empresa = this.empresas[0].Id;
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }
    });

    await this.$store.dispatch('comprobantesAfip/getPermisos').then(res => {
      if (res.exito) {
        this.permiso_portal_iva = res.permiso_portal_iva
        this.permiso_comprobantes_recibidos = res.permiso_comprobantes_recibidos
      } else {
        this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }
    })
  },

  watch: {
    async "filtro.fecha_desde"(val) {
      val ? val : (val = "");
      if (val.length > 0 && Object.keys(this.filtro.empresa).length > 0) {
        await this.getProveedores();
        this.getTiposPorComprobantesExistentes();
      }
    },

    async "filtro.fecha_hasta"(val) {
      val ? val : (val = "");
      if (val.length > 0 && Object.keys(this.filtro.empresa).length > 0) {
        await this.getProveedores();
        this.getTiposPorComprobantesExistentes();
      }
    },

    "filtro.empresa"() {
      this.filtro.fecha_desde = "";
      this.filtro.fecha_hasta = "";
      this.filtro.proveedor = {};
      this.filtro.tipo = {};
      this.filtro.estado = {};
    },

    "filtro.proveedor"(val) {
      val ? val : (val = "");
      if (Object.keys(val).length == 0) {
        this.tiposFiltrados = this.tipos;
      }
    },

    "filtro.tipo"(val) {
      val ? val : (val = "");
      if (
        Object.keys(val).length > 0 &&
        Object.keys(this.filtro.proveedor).length == 0
      ) {
      }
    },
  },

  components: {
    BtnFiltro,
    ModalSubirComprobantes,
    BtnConfirmar,
    FechaPicker,
    SearchDataTable,
    PortalIVA,
    AnularFactura
  },
};
</script>
