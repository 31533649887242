<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Empresa
                    <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id" hide-details outlined
                      dense clearable :items="familias"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Tipo
                    <v-autocomplete v-model="data.tipo" item-text="Nombre" item-value="Id" hide-details outlined dense
                      :items="tipos_reportes" clearable></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Periódo
                    <PeriodoPicker v-model="data.periodo" hide-details clearable />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pt-3 mt-3 d-flex justify-end" style="">
                    <div style="display: flex; align-items: center; gap: 2rem;">
                      <div style="display: flex; margin-bottom: 1.1rem;">
                        <v-checkbox label="F 2002" v-model="formato_2002" hide-details></v-checkbox>
                      </div>
                      <v-btn color="success" :loading="load" @click="generar">
                        <v-icon class="" style="padding-right: 4px;">fas fa-directions</v-icon>
                        Generar reporte
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mt-2">
          <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers" :items="reporte"
            :items-per-page="-1" :loading="load" sort-desc dense>
            <template v-for="header in headers.filter(header =>
              header.hasOwnProperty('formatter')
            )" v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.SumaExento`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaExento) }}
              </span>
              <span v-else>
                {{ item.SumaExento }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaGravado) }}
              </span>
              <span v-else>
                {{ item.SumaGravado }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA21`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaIVA21) }}
              </span>
              <span v-else>
                {{ item.SumaIVA21 }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA105`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaIVA105) }}
              </span>
              <span v-else>
                {{ item.SumaIVA105 }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA27`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaIVA27) }}
              </span>
              <span v-else>
                {{ item.SumaIVA27 }}
              </span>
            </template>

            <template v-slot:[`item.SumaPercepIVA`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaPercepIVA) }}
              </span>
              <span v-else>
                {{ item.SumaPercepIVA }}
              </span>
            </template>
            <template v-slot:[`item.SumaImpInt`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaImpInt) }}
              </span>
              <span v-else>
                {{ item.SumaImpInt }}
              </span>
            </template>
            <template v-slot:[`item.SumaTotal`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaTotal) }}
              </span>
              <span v-else>
                {{ item.SumaTotal }}
              </span>
            </template>

            <template v-slot:[`item.SumaGravado21`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaGravado21) }}
              </span>
              <span v-else>
                {{ item.SumaGravado21 }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado105`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaGravado105) }}
              </span>
              <span v-else>
                {{ item.SumaGravado105 }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado27`]="{ item }">
              <span v-if="formato_2002">
                {{ format_money(item.SumaGravado27) }}
              </span>
              <span v-else>
                {{ item.SumaGravado27 }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import BtnConfirmar from '../../../components/util/BtnConfirmar.vue';
import PeriodoPicker from '../../../components/util/PeriodoPicker.vue';

export default {
  data() {
    return {
      panel: 0,
      load: false,
      data: {
        empresa: null,
        tipo: null,
        periodo: moment().subtract(1, 'months').format('MM/YYYY'),
      },
      formato_2002: false,
      headers: [],
      tipos_reportes: [],
      reporte: []
    };
  },
  methods: {
    async generar() {
      if (!this.data.empresa || !this.data.tipo || !this.data.periodo) {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los campos son obligatorios.',
          color: 'warning'
        })
        return
      }
      const periodo = `01/${this.data.periodo}`
      this.load = true
      await this.$store.dispatch('reportes/getReporte', {
        periodo,
        empresa: this.data.empresa,
        tipo: this.data.tipo
      }).then(res => {
        if (res.exito) {
          this.reporte = res.data
          this.reporte = this.reporte.sort((a, b) =>
            a.Clasificacion.localeCompare(b.Clasificacion)
          )
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      }).catch(err => {
        this.$store.dispatch('show_snackbar', {
          text: err.message,
          color: 'error'
        })
        this.load = false
      })
      this.load = false
    },
    formatMonto(monto, format, funcion) {
      return funcion(monto, format)
    },
    format_money(money) {
      if (!money && money != 0) return ''
      return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
    }
  },
  computed: {
    ...mapState(['familias'])
  },
  watch: {
    'data.tipo'(val) {
      switch (val) {
        case (1):
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion' },
            { text: 'Tipo IVA', value: 'TipoIVA' },
            { text: 'Suma Gravado', value: 'SumaGravado', align: 'end' },
            { text: 'Gravado IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
          ]
          break;
        case 2:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion' },
            { text: 'Tipo IVA', value: 'TipoIVA' },
            { text: 'Suma Gravado', value: 'SumaGravado', align: 'end' },
            { text: 'Gravado IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
          ]
          break;
        case 3:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion' },
            { text: 'Suma Gravado', value: 'SumaGravado', align: 'end' },
            { text: 'Gravado IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
          ]
          break;
        case 4:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion' },
            { text: 'Suma Gravado', value: 'SumaGravado', align: 'end' },
            { text: 'Gravado IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
          ]
          break;
        default:
          break;
      }
    },
    'data.empresa'(val) {
      this.reporte = []
    },
  },
  async created() {
    await this.$store.dispatch('reportes/getTiposReportes').then(res => {
      if (res.exito) {
        this.tipos_reportes = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })
  },
  components: {
    PeriodoPicker,
    BtnConfirmar
  },
};
</script>